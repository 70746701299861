import React, { useState } from 'react';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import TableProvider from './components/context/TableProvider';
import { NotificationProvider } from './components/context/NotificationContext';
import FullFeaturedCrudGrid from './components/DataTable';
import Login from './components/Login';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const handleLogin = () => setLoggedIn(true);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <NotificationProvider>
      {loggedIn ? (
          <TableProvider>
            <FullFeaturedCrudGrid />
          </TableProvider>
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </NotificationProvider>
    </StyledEngineProvider>
  );
}

export default App;
