// api.ts
import Sponsor from "./components/types/Sponsor";
import { randomId } from '@mui/x-data-grid-generator';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

export async function getAllSponsorData(): Promise<Sponsor[]> {
  const response = await fetch(`${API_BASE_URL}/getAll`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-request-token': `${API_TOKEN}`
    },
  });

  const responseData = await response.json() as Sponsor[];

  const dataWithIds: Sponsor[] = responseData.map((sponsor) => ({
    ...sponsor,
    id: randomId(), // Generate a unique ID using randomId
  }));

  return dataWithIds;
}

export async function addSponsor(sponsorUrl: string, sponsorNumber: string): Promise<Sponsor> {
  const response = await fetch(`${API_BASE_URL}/addSponsor`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
      'x-request-token': `${API_TOKEN}`
    },
    body: JSON.stringify({ 
      url: sponsorUrl,
      sponsorNumber: sponsorNumber
    }),
  });

  if (!response.ok) {
    throw new Error(`API request failed: ${response.status}`);
  }

  const { sponsor } = await response.json();
  const responseData: Sponsor = {...sponsor};
  
  responseData.id = randomId();
  return responseData;
}

export async function excludeSponsor(sponsorNumber: string, index: number): Promise<void> {
  const response = await fetch(`${API_BASE_URL}/excludeSponsor`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-request-token': `${API_TOKEN}`
    },
    body: JSON.stringify({ "sponsorNumber": sponsorNumber, index: index }),
  });

  if (!response.ok) {
    throw new Error(`API request failed: ${response.status}`);
  }
}

export async function setSponsorCurrent(sponsorNumber: string, index: number): Promise<void> {
  const response = await fetch(`${API_BASE_URL}/setCurrent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-request-token': `${API_TOKEN}`
    },
    body: JSON.stringify({ "sponsorNumber": sponsorNumber, index: index }),
  });

  if (!response.ok) {
    throw new Error(`API request failed: ${response.status}`);
  }
}

export async function setSponsorToNext(sponsorNumber: string, index: number): Promise<void> {
  const response = await fetch(`${API_BASE_URL}/setToNext`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-request-token': `${API_TOKEN}`
    },
    body: JSON.stringify({ "sponsorNumber": sponsorNumber, index: index }),
  });

  if (!response.ok) {
    throw new Error(`API request failed: ${response.status}`);
  }
}

export async function removeSponsor(sponsorNumber: string, index: number): Promise<void> {
  const response = await fetch(`${API_BASE_URL}/removeSponsor`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-request-token': `${API_TOKEN}`
    },
body: JSON.stringify({ "sponsorNumber": sponsorNumber, index: index }),
  });

  if (!response.ok) {
    throw new Error(`API request failed: ${response.status}`);
  }
}

export async function removeAll(): Promise<void> {
  const response = await fetch(`${API_BASE_URL}/removeAll`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-request-token': `${API_TOKEN}`
    },
  });

  if (!response.ok) {
    throw new Error(`API request failed: $response.status} ${response.text}`);
  }
}