import React, { createContext, useContext, ReactNode } from 'react';
import { getAllSponsorData } from '../../api';
import Sponsor from '../types/Sponsor';
import { useNotification } from './NotificationContext';

interface TableProviderProps {
  children: ReactNode;
}

interface TableContextValue {
  data: Sponsor[];
}

const TableContext = createContext<TableContextValue | undefined>(undefined);

export function useTableContext() {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider');
  }
  return context;
}

export default function TableProvider({ children }: TableProviderProps) {
  const [data, setData] = React.useState<Sponsor[]>([]);
  const { showNotification ,showLoadingBackdrop, hideLoadingBackdrop } = useNotification();

  React.useEffect(() => {
    async function fetchData() {
      try {
        showLoadingBackdrop();
        const sponsors = await getAllSponsorData();

        setData(sponsors);
        hideLoadingBackdrop();
      } catch (error) {
        hideLoadingBackdrop();
        showNotification("Ошибка загрузки данных таблицы", "error");
      }
    }

    fetchData();
  }, []);

  const contextValue: TableContextValue = {
    data
  };

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
}
