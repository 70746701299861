import React, { useState } from 'react';
import { SHA1 } from 'crypto-js';
import { Button, TextField, Typography, Paper, Grid, Container, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

interface LoginProps {
  onLogin: () => void;
}

const token = process.env.REACT_APP_API_TOKEN as string;

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleLogin = () => {
    const tokenHash = SHA1(token).toString();

    if (password === tokenHash) {
      onLogin();
    } else {
      setShowAlert(true);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <Container component="main" maxWidth="xs" style={{ paddingTop: '20px' }}>
      <Paper elevation={3} style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Вход
        </Typography>
        <form style={{ width: '100%', marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="password"
                label="Введите пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={handleLogin}
          >
            Войти
          </Button>
        </form>
      </Paper>

      {/* Custom Alert */}
      <Snackbar open={showAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          Неверный пароль. Пожалуйста, попробуйте еще раз.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
