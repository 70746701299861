import * as React from 'react';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRowsProp } from '@mui/x-data-grid';
import { Box } from '@mui/material';

import { useNotification } from './context/NotificationContext';
import { addSponsor, removeAll } from '../api';

interface FormDialogProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
}

export default function FormDialog({ setRows }: FormDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [sponsorList, setSponsorList] = React.useState('');

  const { showNotification, showLoadingBackdrop, hideLoadingBackdrop } = useNotification();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleAddSponsorList = async () => {
    const sponsorArray = sponsorList.split('\n');
    const cleanSponsorArray = sponsorArray
      .map((sponsor) => sponsor.trim())
      .filter(Boolean);

    if (cleanSponsorArray.length === 0) {
      showNotification(`Неверный формат списка!`, 'error');
      return;
    }

    try {
      setOpen(false);
      showLoadingBackdrop();
      for (const sponsor of cleanSponsorArray) {
        //TODO: add support adding of by numbers list, validate link through regexp
        const newRow = await addSponsor(sponsor, '');

        setRows((oldRows: GridRowsProp) => [...oldRows, newRow]);
      }
      setSponsorList('')
      showNotification('Ссылки успешно добавлены!', 'success');
      hideLoadingBackdrop();
    } catch (error) {
      hideLoadingBackdrop();
      showNotification(`Ошибка при добавлении списка! ${error}`, 'error');
    }
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSponsorList(event.target.value);
  };

  
  const handleConfirmDeleteAllRows = async () => {
    try {
      setOpenConfirm(false);
      showLoadingBackdrop();
      
      await removeAll();
      setRows(() => []);

      hideLoadingBackdrop();

      showNotification('Все строки удалены', 'success');
    } catch (error) {
      hideLoadingBackdrop();
      showNotification(`Ошибка при удалении строк! ${error}`, 'error');
    }
  };

  return (
    <div>
      {/* TODO: Try to float to right removeAll button, all styles below doesnt't help*/}
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: "space-between",
          alignItems: "center"
        }}
        component="span"
        m={1}
      >
        <Box>
          <Button variant="outlined" onClick={handleClickOpen}>
            Добавить список ссылок
          </Button>
        </Box>
        <Box>
          <Button variant="outlined" color="secondary" onClick={handleClickOpenConfirm}>
            Удалить все строки
          </Button>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Добавить список ссылок</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите список ссылок. Каждая новая ссылка должна начинаться с новой строки.
          </DialogContentText>
          <TextareaAutosize
            autoFocus
            minRows={5}
            style={{ width: '100%' }}
            value={sponsorList}
            onChange={handleTextAreaChange}
            placeholder={`https://faberlic.com/register?sponsornumber=12345&lang=ru&r=1000034210371\nhttps://faberlic.com/register?sponsornumber=56789&lang=ru&r=1000034210371`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleAddSponsorList}>Добавить</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirm} onClose={handleClose}>
        <DialogTitle>Подтверждение удаления</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите удалить все строки?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            Отмена
          </Button>
          <Button onClick={handleConfirmDeleteAllRows} color="secondary">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
