import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { useTableContext } from './context/TableProvider';
import { useNotification } from './context/NotificationContext';
import FormDialog from './FormDialog';
import {
  getAllSponsorData,
  addSponsor,
  setSponsorToNext,
  setSponsorCurrent,
  excludeSponsor,
  removeSponsor,
} from '../api';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => {
      const lastIndex = oldRows.length > 0 ? oldRows[0].index : -1;
      const newIndex = lastIndex + 1;
      return [
        ...oldRows,
        {
          id,
          name: '',
          surname: '',
          position: 'next',
          isNew: true,
          index: newIndex,
        },
      ];
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'url' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Добавить ссылку
      </Button>
      <FormDialog setRows={setRows}/>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  // Data hooks
  const [rowModesModel, setRowModesModel] =
    useState<GridRowModesModel>({});
  const { data: initialRows } = useTableContext();
  const [rows, setRows] = useState<GridRowModel[]>([]);
  
  // Notification hooks
  const { showNotification ,showLoadingBackdrop, hideLoadingBackdrop } = useNotification();

  useEffect(() => {
    setRows(initialRows);
  }, [initialRows]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const sponsor = rows.find((row) => row.id === id);
    if (sponsor) {
      try {
        showLoadingBackdrop();
        await handleWithReload(async () =>
          removeSponsor(sponsor.sponsorNumber, sponsor.index)
        );
        hideLoadingBackdrop();
      } catch (error) {
        hideLoadingBackdrop();
        showNotification(`Ошибка при удалении ссылки! ${error}`, 'error');
        return;
      }
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleWithReload = async (action: () => Promise<void>) => {
    showLoadingBackdrop();
    await action();
    const allSponsors = await getAllSponsorData();
    setRows(allSponsors);
    hideLoadingBackdrop();
    showNotification('Операция выполнена успешно!', 'success');
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    let updatedRow: GridRowModel;
    if (newRow.isNew) {
      try {
        showLoadingBackdrop();
        updatedRow = await addSponsor(
          newRow.url,
          newRow.sponsorNumber
        );
        updatedRow.isNew = false;
        hideLoadingBackdrop();
        showNotification('Ссылка добавлена успешно!', 'success');
      } catch (error) {
        hideLoadingBackdrop();
        showNotification(`Ошибка при добавлении ссылки! ${error}`, 'error');
        return;
      }
    } else {
      updatedRow = { ...newRow, isNew: false };
    }

    setRows(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    const originalRow = rows.find((row) => row.id === newRow.id);
    if (newRow.position !== originalRow?.position) {
      try {
        if (newRow.position === 'current') {
          await handleWithReload(async () =>
            await setSponsorCurrent(
              newRow.sponsorNumber,
              newRow.index
            )
          );
          return;
        } else if (newRow.position === 'excluded') {
          await handleWithReload(async () =>
            await excludeSponsor(
              newRow.sponsorNumber,
              newRow.index
            )
          );
          return;
        } else if (newRow.position === 'next') {
          await handleWithReload(async () =>
            await setSponsorToNext(
              newRow.sponsorNumber,
              newRow.index
            )
          );
        }
      } catch (error) {
        hideLoadingBackdrop();
        showNotification(
          `Ошибка при смене позиции ссылки! ${error}`,
          'error'
        );
      }
    }

    return updatedRow;
  };

  const processRowUpdateError = (error: unknown) => {
    hideLoadingBackdrop();
    showNotification(`Ошибка при обновлении таблицы! ${error}`, 'error');
  };

  const handleRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    { field: 'index', headerName: 'ID', width: 60, editable: false },
    {
      field: 'sponsorNumber',
      headerName: 'Номер консультанта',
      width: 150,
      editable: true,
    },
    { field: 'url', headerName: 'URL', width: 220, editable: true },
    { field: 'name', headerName: 'Имя', width: 80, editable: true },
    { field: 'surname', headerName: 'Фамилия', width: 80, editable: true },
    {
      field: 'position',
      headerName: 'Позиция',
      width: 140,
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 'excluded', label: 'исключена' },
        { value: 'current', label: 'текущая' },
        { value: 'next', label: 'следующая' },
      ],
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode =
          rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              key={id}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              key={id}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            key={id}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            key={id}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'index', sort: 'asc' }],
          },
        }}
      />
    </Box>
  );
}
