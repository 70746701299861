import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Snackbar, Alert } from '@mui/material';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type NotificationSeverity = 'success' | 'error';

interface NotificationContextData {
  showNotification: (message: string, severity: NotificationSeverity) => void;
  showLoadingBackdrop: () => void;
  hideLoadingBackdrop: () => void;
}

const NotificationContext = createContext<NotificationContextData | undefined>(undefined);

interface NotificationProviderProps {
  children: ReactNode;
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState<NotificationSeverity>('success');
  const [loadingBackdrop, setLoadingBackdrop] = useState(false); // Add this state

  const showNotification = (message: string, severity: NotificationSeverity) => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setNotificationOpen(true);
  };

  const handleCloseNotification = () => {
    setNotificationOpen(false);
  };

  const showLoadingBackdrop = () => {
    setLoadingBackdrop(true);
  };

  const hideLoadingBackdrop = () => {
    setLoadingBackdrop(false);
  };

  return (
    <NotificationContext.Provider value={{ showNotification, showLoadingBackdrop, hideLoadingBackdrop }}>
      {children}
      <Snackbar open={notificationOpen} autoHideDuration={10000} onClose={handleCloseNotification}>
        <Alert onClose={handleCloseNotification} severity={notificationSeverity}>
          {notificationMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
}
